export const text: any = {
	aboutPage: {
		title: 'Get an answer in minutes.', // See your pre-qualified offers in as little as 2 minutes.
		subTitle: 'Completing the form below will not affect your credit score.',  // Completing the form below will not affect your credit score.
		cashApplicationTitle: 'Complete the form below for {{title}}',
		cashApplicationSubTitle: 'With a Co-Borrower, you can rely on joint income to qualify for better credit options.',
		coBorrowerTitle: 'Tell me about your Co-Borrower.',
		coBorrowerSubTitle: 'With a Co-Borrower, you can rely on joint income to qualify for better credit options.',
		primaryBorrowerTitle: 'Complete the Application Below to Get Started with Purchasing Your Vehicle',
		loanPurposePlaceholder: 'Pick your loan',
		haveOfferCodeText: null,
		housingCostHint: 'Estimate monthly housing cost. Homeowners, include all mortgage payments (first & second mortgages, and home equity loans). Renters, please enter monthly rent.',
		grossAnnualIncomeHint: 'Your before tax income can include commissions and tips. Please do not include non-applicant income. Inclusion of alimony or child support is optional.',
		textBelowSubmitButton: 'No credit impact for checking your rates.'
	},
	stepPage: {
		title: 'See your pre-qualified offers in minutes.', // See your pre-qualified offers in as little as 2 minutes.
		subtitle: 'Completing the form below will not affect your credit score.',  // Completing the form below will not affect your credit score.
		next: 'Next',  // Next
		back: 'Back',  // Back
		reset: 'Reset',  // Reset
	},
	// radioButton: {
	//   yes: 'Yes',  // Back
	//   no: 'No',  // no
	// },
	tradePage: {
		title: 'Last step before you can see your offers.', // Last step before you can see your offers.
		vehicleInformation: 'Vehicle Information', // Vehicle Information
		identificationNumber: 'Do you have Vehicle Identification Number?', // Do you have Vehicle Identification Number?
		beforeStep: 'Last step before you can see your offers.?', // Last step before you can see your offers.
		loanBalance: 'Current Loan Balance', // Current Loan Balance
		paymentMonthly: 'Monthly Payment', // Monthly Payment
		outstandingBalance: 'Est. Outstanding Balance', // Est. Outstanding Balance
		Change: 'Change', // Change
		informationVehicle: 'Vehicle information', // Vehicle information
		selectedVehicle: 'Your Selected Vehicle', // Your Selected Vehicle
		Mileage: 'Mileage', // Mileage
		accessories: 'Please select vehicle accessories.', // Please select vehicle accessories.
		noTradesScenarioTitle: 'We need additional information before you can see your offers.',
		loanTitle: 'Current Loan Balance',
		tradeLineTitle: 'Please select a trade-line or "I do not see my {{tradeLineDisplay}} loan in this list" option to enter the current loan details',
		tradeLineDisplayTitle: 'Do you want to transfer balance from another {{tradeLineDisplay}} loan? Select Skip below if you are not transfering balance.',
		loanPurposeConfigHint: 'Please provide approximate outstanding loan amount. You may find on your current loan statement',
		loanPurposeConfigError: ' Please enter a valid Payoff amount value greater than {{loanAmount}}.',
		customMonthlyPayment: 'Please enter current monthly payment',
		noVehicleDisclosure: 'Choosing this option will give you preliminary loan rates. If you already have a vehicle in mind, enter that vehicle information below to receive more accurate rates.',
		noVehicleDisclosure_refi: 'Choosing this option will give you preliminary loan rates. If you are able to find vehicle information, enter vehicle information below to receive more accurate rates.',
		propertyAddressTitle: 'Update address if your property address is not the same as the address you provided',
		tradeSelectionTextLong: {
			'R': 'Select the vehicle loan that you want to refinance',
			'CC': 'If approved, would you like to transfer an existing balance to your WECU Visa?',
			'CLI': 'Select the Credit Card that you are requesting a Credit Limit increase on',
			'PPDC': 'Select the loans that you want to consolidate',
		},
	},
	offerPages: {
		headlineTitle1: 'Congratulations, {{applicantFirstName}}! ', // Congratulations, Iram! Several
		headlineTitle2: 'You have pre-qualified offers available. ', // Congratulations, Iram! Several
        ccHeadlineTitle: 'You have {{pre-qualified}} card offers available.',
		termBasedLoanSubTitle: 'Select or customize your {{pre-qualified}} offer to continue to the next step.',
		preQualified: 'These Pre-Qualified offers are based on the information you provided and in your credit file. They\n' +
			'                      are initial, conditional offers and are subject to change based on completion of a final application,\n' +
			'                      verification of information you provided, and other conditions determined solely by WECU. The\n' +
			'                      offers above are not guaranteed and not a commitment to lend. ' +
			'Pre-qualification offers are valid for 30 days. Rates will be locked in upon final submission of your application.',
		aprLegalNoteTermBasedLoan: 'The Annual Percentage Rate (APR) is the yearly rate at which your loan accrues interest. Rates will\n' +
			'                      vary based on your credit, loan size and value of collateral provided in relation to your loan amount. APRs may range from  5.74% to\n' +
			'                      19.24%. Members with good credit financing vehicles with low loan-to-value ratios may qualify for the lowest rates. Minimum and maximum loan amounts and APRs offered may vary according to state law and\n' +
			'                      are subject to change.',
		aprLegalNoteNotTermBasedLoan: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit rating, ' +
		'amount financed and other factors. Rates and terms are subject to change without prior notice; ' +
		'other restrictions may apply.',
		aprCCNoteContent: '',
		paymentExample: 'Payment Example: Monthly payments for a $10,000 loan at 5.74% APR with a term of 4 years would result in 48 monthly payments of $234.74.',
		additionalLine: 'Submit your application today to lock in your rate!',
		textAboveSliderInstalment: 'How much do you want to borrow?',
		textAboveSliderRevolving: 'Select your desired line of credit amount',
		discountText: '(Includes {{_offer.achDiscountRate}}% ACH discount)',
		achDiscountLabel: 'Do you want save by signing up for automatic payments?'
	},
	financePage: {
		pageTitle: 'Review and Submit. Final Submit Page',
		headline: 'Your application is almost ready for submission.',
		subHeadline: null,
		mobileHeadline: 'Your application is almost ready for submission.',
		pendHeadline: 'Based on the information provided, your application needs further review. Please provide additional details so we can expedite the review of your application.',
		employmentLengthHelpTextYear: 'Please enter years of employment.',
		employmentLengthHelpTextMonth: 'Please enter month of employment.',
		noCollateralScenarioTitle: 'The presented offer is without the vehicle value. Please add the collateral information to get the final offer.',
		noOfferStatusTitle: 'No offers available for your selections. Please change your loan amount and/or other selections to see available offers',
		downPayment: '(Estimated. Final down payment amount may vary)',
		applicationCheckboxTitle: 'By clicking the Submit button below, all borrowers consent, acknowledge, and agree to the following:',
		taxIDDisplayText: 'Tax Identification Number (SSN/ITIN)',
		taxIDErrorText: 'Tax Identification Number is required',
		finalSubmitLoaderMessage: '' //Your application is in transit to {{lenderName}}.
	},
	documentUploadPage: {
		subHeadline: 'We received your application and need more information to verify your identity and/or eligibility. Please upload documents or call us with any questions.',
		footerLine: 'If you need more time, you may return to this page by going to apply.wecu.com and clicking My Application in the upper right corner.'
	},
	headerMenu: {
		title: 'WECU UAT HEADER'
	},
	footerMenu: {
		title: 'WECU UAT FOOTER'
	},
	MESSAGES: {
		OFFERS: {
			LENDER_APPROVED_NO_RATES: {
				TITLE: 'Thank you for applying with WECU!',
				MESSAGE: 'Thank you for submitting your application. We need additional information before ' +
					'you can see your offer. Please contact your WECU FCU ' +
					'Member Services Representative at 360-676-1168 so that we can get you the loan that meets your needs.'
			},
			NO_BUREAU_REPORT: {
				TITLE: '',
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			NO_BUREAU_HIT_COBORROWER: {
				TITLE: '',
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			NO_OFFER: {
				TITLE: '',
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			PEND: {
				TITLE: '',
				MESSAGE: ': Based on the information provided, your application needs further review. Please complete the following information and a representative will be in contact with you within the next 1-2 business days. If you have any questions about your application, please call us at 360-676-1168.'
			},
			CROSS_SELL_DISCLOSURE: {
				MESSAGE: ''
			},
			NO_OFFER_NO_HIT: {
				TITLE: '',
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			NO_OFFER_HARD_DECLINE: {
				TITLE: '',
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			NO_OFFER_FOUND: {
				TITLE: '',
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			NO_OFFER_SOFT_DECLINE: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			NO_OFFER_PRE_FAIL: {
				TITLE: '',
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			NO_OFFER_WITH_LTV: {
				TITLE: '',
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			NO_OPTIONS: {
				MESSAGE: 'Thank you for applying with WECU. We regret to inform you that your application does not meet our criteria for approval. You will receive an email with an attached letter advising you of the reasons for this decision within the next few days. If you have any questions, contact us at 360-676-1168.'
			},
			PAYMENT_EXAMPLE: {
				MESSAGE: 'Payment Example: Monthly payments for a $10,000 loan at 3.19% APR with a term of 4 ' +
					'years would result in 48 monthly payments of $222.18.'
			},
			APR_LEGAL_NOTE: {
				MESSAGE: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit ' +
					'worthiness, amount financed and other factors.  Rates and terms are subject to change without prior ' +
					'notice. Other restrictions apply.'
			},
			APR_LEGAL_NOTE_Non_Auto: {
				MESSAGE: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit worthiness, ' +
					'amount financed and other factors. Rates and terms are subject to change without prior notice. ' +
					'Other restrictions may apply.'
			},
			PRE_QUALIFIED_OFFER: {
				MESSAGE: 'The Pre-Qualified offers presented above are based on the information you provided and information from ' +
					'your credit file. These are initial, conditional offers that are subject to change, based on the completion of a ' +
					'final credit application, verification of the information you provided, your ability to repay, and other conditions. ' +
					'The offers are non-binding, are not guaranteed, and are not a commitment to lend'
			},
		},
		CASH_APPLICATION: {
			TITLE: 'Thank you for applying with WECU!',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our Representatives will be contacting' +
				' you in the next 1-2  business days 24 hours with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		PROVISIONING_COMPLETE: {
			TITLE: 'Thank You! ',
			MESSAGE: 'We appreciate your business and the opportunity to serve you. We will review your application and a representative will contact you within 1-2 business days.' +
				'If you have any questions about your application, please call us at {{phoneNumber}}. '

		},
		FINANCIAL_SUBMITTED: {
			TITLE: 'Thank you for applying with WECU!',
			MESSAGE: 'We appreciate your business and the opportunity to serve you. We will review your application and a representative will contact you within 1-2 business days.' +
				'If you have any questions about your application, please call us at {{phoneNumber}}. '

		},
		FINANCIAL_SUBMITTED_FAILED: {
			TITLE: 'Thank you for applying with WECU!',
			MESSAGE: 'We appreciate your business and the opportunity to serve you. We will review your application and a representative will contact you within 1-2 business days.' +
				'If you have any questions about your application, please call us at {{phoneNumber}}. '
		},
		FINANCIAL_SUBMITTED_FRAUD: {
			TITLE: 'Thank you for applying with WECU!',
			MESSAGE: 'We appreciate your business and the opportunity to serve you. We will review your application and a representative will contact you within 1-2 business days.' +
				'If you have any questions about your application, please call us at {{phoneNumber}}. '
		},
		FINANCIAL_SUBMITTED_FRAUD_REVIEW: {
			TITLE: 'Thank you for applying with WECU!',
			MESSAGE: 'We appreciate your business and the opportunity to serve you. We will review your application and a representative will contact you within 1-2 business days.' +
				'If you have any questions about your application, please call us at {{phoneNumber}}. '
		},
		FINANCIAL_SUBMITTED_FRAUD_DECLINE: {
			TITLE: 'Your application needs further review.',
			MESSAGE: 'We were not able to confirm your identity with the information provided, so your application is currently on hold. Please call us at {{phoneNumber}} to proceed. ' +
			'Thank you for choosing WECU.'
		},
		EXCEPTION: {
			TITLE: 'Thank you for applying with WECU!',
			MESSAGE: 'We appreciate your business and the opportunity to serve you. We will review your application and a representative will contact you within 1-2 business days.' +
			'If you have any questions about your application, please call us at {{phoneNumber}}. '

		},
		DISCLOSURE: {
			FINAL: {
				CONSENT_1: 'You are providing written consent under the Fair Credit Reporting Act to authorize WECU FCU to',
				CONSENT_2: 'obtain your credit report with a hard credit inquiry ',
				CONSENT_3: ' from credit reporting agencies. You acknowledge that this is an application for credit and authorize ' +
					'WECU FCU to obtain such information solely to evaluate your credit application. ' +
					'By clicking this box, you consent, acknowledge, and agree that WECU FCU can process your final application for credit.' +
					'You are also consenting to allow us offer you savings and benefits on other products.'
			},
			SOFT_INQUIRY: {
				CONSENT_1: ' You are providing express written consent under the Fair Credit Reporting Act to authorize ' +
					'WECU Federal Credit Union to obtain your credit profile from credit reporting agencies. You authorize ' +
					'WECU Federal Credit Union to obtain such information solely to evaluate your eligibility for certain loan product ' +
					'pre-qualification offers and other cross-sell offers and acknowledge that this is not an application for credit.',
				CONSENT_UPTO_LENDER: '',
				CONSENT_PRIVACY: 'You agree that you received WECU FCU <a ' +
					'href="" target="_new">Privacy Policy</a>. ' +
					'You also verified that you can read and print or keep these electronic documents and you ' +
					'certify that your selections and information in this form are true, correct and complete.',
				CONSENT_MARKETING: 'I hereby consent to receive email, SMS or other marketing communications from WECU FCU.' +
					'I further expressly agree that WECU FCU may contact me at the calling numbers providing including ' +
					'my cellular number, via alive, auto dialer, prerecorded telephone call, text chat or email message. ' +
					'I further understand that my telephone company may impose charges on me for these contacts and I am not ' +
					'required to provide this consent to be effective even if my calling numbers or email address are included on ' +
					'ado-not-call list or registry. I understand that my consent to receive calls is not required in order to ' +
					'purchase any goods or services. If you do not want to receive any marketing communications just un-check the box.'
			},
			MARKETING_INQUIRY: {
				CONSENT_MARKETING_1: 'I hereby consent to receive email, SMS or other marketing communications from WECU FCU. ' +
					'I further expressly agree that WECU FCU ',
				CONSENT_MARKETING_2: ' may contact me at the calling numbers providing including my cellular number, via live, ' +
					'auto dialer, prerecorded telephone call, text, chat or email message. I further understand that my telephone ' +
					'company may impose charges on me for these contacts and I am not required to provide this consent to be effective even if ' +
					'my calling numbers or email address are included on a do-not-call list or registry. I understand that my ' +
					'consent to receive calls is not required in order to purchase any goods or services. ' +
					'If you do not want to receive any marketing communications just un-check the box'
			}
		},
		PROVISIONS: {
			THANKYOU: {
				TITLE: 'You are all set.',
				MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
					'contacting you in the next 1-2 business days with the next steps. ' +
					'You can reach us at {{phone}} if you have any questions in the meantime.'
			},
			'THANKYOU-APPROVED': {
				TITLE: 'You are all set.',
				MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
					'contacting you in the next 1-2 business days with the next steps. ' +
					'You can reach us at {{phone}} if you have any questions in the meantime.'
			},
		}
	}
}
