import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';


import {AlphabetDirective} from './directives/alphabet-only.directive';
import {AlphabetWithSpaceDirective} from './directives/alphabet-with-space.directive';
import {CustomMinMaxValidatorDirective} from './directives/min-max-validator.directive';
import {CurrencyNumberPipe} from './pipes/currency-number.pipe';
import {PoboxAllowedDirective} from './directives/pobox-allowed.directive';
import {InputNumberDirective} from './directives/input-number.directive';
import {NumberDirective} from './directives/numbers-only.directive';

import {SafePipe} from './pipes/safe.pipe';
import {ReplaceStringPipe} from './pipes/replace-string.pipe';
import {ConsoleLogPipe} from './pipes/console-log.pipe';
import {SubmitIfValidDirective} from './directives/submit.directive';
import {ReplacePipe} from './pipes/replace.pipe';
import {PhoneMaskDirective} from './directives/phone-mask.directive';
import {CurrencyPipe} from './pipes/currency.pipe';
import {VinCheckValidatorDirective} from './directives/vin-check-validator.directive';
import {GooglePlacesDirective} from './directives/google-places.directive';
import {UppercaseDirective} from './directives/uppercase.directive';
import {SsnMaskDirective} from './directives/ssn-mask.directive';
import {CurrencyFormatDirective} from './directives/currency-format.directive';
import {MessagePipe} from './pipes/message.pipe';
import {NumbersWithoutZeroDirective} from './directives/numbers-only-no-zero.directive';
import {TrimDirective} from './directives/trim.directive';
import {ParentFormDirective} from './directives/parent-form.directive';
import {ZipcodeDirective} from './directives/zipcode.directive';
import {PhoneMaskPipe} from './pipes/phone-mask.pipe';
import {EmailValidatorDirective} from './directives/email-validator.directive';
import {SSNValidatorDirective} from './directives/ssn-validator.directive';
import {SearchCountryPipe} from './pipes/search-country.pipe';
import {FilterArrayPipe} from "./pipes/filter-array.pipe";

// Interface
export {ProgressButtonOptions} from './interfaces/progress-button.interface';

// Auth Models
export {AuthToken, Credential} from './models/auth';

// Models
export {KeyIdentifierModel} from './models/keyIdentifier.model';
export {LoginUserModel} from './models/login-user.model';
export {ApplicantModel} from './models/applicant.model';
export {ApplicationModel} from './models/application.model';
export {CollateralModel} from './models/collateral.model';
export {AddressModel} from './models/address.model';
export {EmploymentModel} from './models/employment.model';
export {CollateralPropertyModel} from './models/collateral-property.model';
export {IncomeModel} from './models/income.model';
export {ResidenceModel} from './models/residence.model';
export {TaxModel} from './models/tax.model';
export {TradelineModel} from './models/tradeline.model';
export {CSPostResponseModel} from './models/csPostResponse.model';
export {CustomerConsentModel} from './models/customer-consent.model';
export {SearchModel} from './models/search.model';
export {TradeLinesModel} from './models/tradeLines.model';
export {ApprovalApplicationModel} from './models/approvalApplication.model';
export {ConsentModel} from './models/consent.model';
export {OfferOptionsVO} from './models/offerOptions.VO';
export {LoanAmountVariationsModel} from './models/loan-amount.model';
export {OfferModel} from './models/offer.model';
export {OfferVariationModel} from './models/offerVariation.model';
export {CCOfferVariationModel} from './models/CCOfferVariation.model';
export {FinanceModel} from './models/finance.model';
export {LenderModel} from './models/lender.model';
export {ApplicationDocumentModel} from './models/application-document.model';
export {AchModel} from './models/ach.model';
export {ProgressButtonModel} from './models/progress-button.model';
export {Country} from './models/country.model';
export {AddOnProductModel} from './models/add-on-product-model';

// Pipes
export {CurrencyPipe} from './pipes/currency.pipe';
export {ReplaceStringPipe} from './pipes/replace-string.pipe';
export {FilterArrayPipe} from './pipes/filter-array.pipe';

// Enums
export {
    ApplicationStatusEnum,
    ApplicationTypeEnum,
    AutoTypeEnum,
    VehicleTypeEnum,
    CountryList,
    StateList,
    AutoLoanPurpose,
    ProvisionStatusEnum,
    ApplicantType
} from './enums/process-state.enum';
export {ConsentEnum, RadioTemplate, Event} from './enums/consent-enum';
export * from './enums/stipulations.enum';

@NgModule({
    imports: [CommonModule],
    declarations: [AlphabetDirective, AlphabetWithSpaceDirective, SafePipe, ReplaceStringPipe, ConsoleLogPipe, SubmitIfValidDirective,
        CustomMinMaxValidatorDirective, CurrencyFormatDirective, CurrencyNumberPipe, PoboxAllowedDirective, InputNumberDirective,
        ReplacePipe, PhoneMaskDirective, NumbersWithoutZeroDirective, CurrencyPipe, PhoneMaskPipe, VinCheckValidatorDirective, GooglePlacesDirective,
        UppercaseDirective, SsnMaskDirective, NumberDirective, MessagePipe, TrimDirective, ParentFormDirective, ZipcodeDirective, EmailValidatorDirective, SSNValidatorDirective, SearchCountryPipe,
		FilterArrayPipe],
    exports: [AlphabetDirective, AlphabetWithSpaceDirective, CustomMinMaxValidatorDirective, InputNumberDirective, SafePipe, ReplacePipe,
        CurrencyFormatDirective, CurrencyNumberPipe, PoboxAllowedDirective, ReplaceStringPipe, ConsoleLogPipe, SubmitIfValidDirective,
        PhoneMaskDirective, NumbersWithoutZeroDirective, CurrencyPipe, PhoneMaskPipe, VinCheckValidatorDirective, GooglePlacesDirective,
        UppercaseDirective, SsnMaskDirective, NumberDirective, MessagePipe, TrimDirective, ParentFormDirective, ZipcodeDirective, EmailValidatorDirective, SSNValidatorDirective, SearchCountryPipe,
		FilterArrayPipe]
})
export class DataModelsModule {
}
