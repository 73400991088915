import {
	AfterViewInit, ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input, OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {
	APP_CONFIG,
	APP_ENV_CONFIG,
	APP_TEXT_CONFIG,
	SharedKeyDataService
} from '@creditsnap/app-config';
import * as _ from 'lodash';
import {RadioOptionModel} from '../radio-group/radio-option.model';
import {ApplicationModel, KeyIdentifierModel, RadioTemplate} from '@creditsnap/data-models';
import {MatStepper} from '@angular/material/stepper';
import {Subscription} from 'rxjs';

@Component({
	selector: 'creditsnap-product-selection',
	templateUrl: './product-selection.component.html',
	styleUrls: ['./product-selection.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProductSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('productSelectionStepper') productSelectionStepper: MatStepper;
	@Input() navigationHidden = false;
	@Output() productSelectionComplete: EventEmitter<void> = new EventEmitter<void>();
	@Output() selectedProduct = new EventEmitter();

	subscription: Subscription;
	keyIdentifier: KeyIdentifierModel;
	loanPurposesByType: any = {};
	loanPurposes: any = [];

	productTypeListRadioObject: RadioOptionModel = new RadioOptionModel();
	selectedProductCategory: any;

	productListRadioObject: RadioOptionModel;
	selectedProductData: any;
	skipSubProductStep = false;

	subProductListRadioObject: RadioOptionModel;
	selectedSubProductData: any;

	constructor(@Inject(APP_CONFIG) public appConfig: any,
				@Inject(APP_ENV_CONFIG) public environment: any,
				@Inject(APP_TEXT_CONFIG) public appTextConfig: any,
				private cdr: ChangeDetectorRef,
				private sharedKeyDataService: SharedKeyDataService) {
		this.subscription = this.sharedKeyDataService.keyIdentifier$
			.subscribe(async (data) => {
				this.keyIdentifier = data;
				this.keyIdentifier.loanPurposeConfig = this.appConfig.loanPurpose[this.keyIdentifier.loanPurpose];
			});
	}

	ngOnInit(): void {
		console.log('this.appConfig.loanPurpose >>>>>>>>>>>>>.', this.appConfig.loanPurpose);
		_.forEach(this.appConfig.loanPurpose, (value: any) => {
			// eslint-disable-next-line no-prototype-builtins
			if (!value.hasOwnProperty('hideFromDropdown') || !value.hideFromDropdown) {
				this.loanPurposes.push(value);
			}
		});
		this.loanPurposesByType = _.groupBy(this.loanPurposes, 'productName');

		/* ========== Set Product Category List array ========== */
		this.productTypeListRadioObject.groupName = 'product-type';
		this.productTypeListRadioObject.items = [];
		Object.keys(this.loanPurposesByType).forEach((type: string) => {
			console.log('this.appConfig.loanCategories >>>>>>>>', this.appConfig.loanCategories);
			if(!this.appConfig.loanCategories[type]) {
				return;
			}
			this.productTypeListRadioObject.items.push({
				heading: this.appConfig.loanCategories[type].name,
				name: type,
				value: type,
				id: type,
				description: this.appConfig.loanCategories[type].description || '',
				icon : this.appConfig.loanCategories[type].icon,
			});
		})
	}

	ngAfterViewInit() {
		// Disable animation to get the correct step length
		this.productSelectionStepper.disableRipple = true;
	}

	/**
	 * Product category selection
	 * @param category
	 */
	changeProductCategory(category: any) {
		this.selectedProductCategory = category;

		/* ========== Set Product List array  based on category selection ========== */
		this.productListRadioObject = new RadioOptionModel();
		this.productListRadioObject.subProductTemplate = RadioTemplate.RadioCard;

		Object.keys(this.loanPurposes).forEach((loanPurposeId: string) => {
			const loanPurpose = this.loanPurposes[loanPurposeId];
			if(loanPurpose.productName === category.value) {
				console.log('loanPurpose >>>>>>>>>>>>>>>', loanPurpose);
				this.productListRadioObject.items.push({
					heading: loanPurpose.title,
					name: loanPurpose.id,
					value: loanPurpose.id,
					id: loanPurposeId,
					description: loanPurpose.description || '',
					icon : loanPurpose.icon,
				});
			}
		});
	}

	goToProductSelection() {
		if(this.productListRadioObject?.items?.length === 1) {
			this.changeProductSelection(this.productListRadioObject.items[0]);
			this.submitProductSelection();
		} else {
			this.productSelectionStepper.next();
		}
	}

	updateLoanPurposeConfig(loanPurpose: any) {
		this.keyIdentifier.loanPurpose = loanPurpose;
		this.keyIdentifier.loanPurposeConfig = this.appConfig.loanPurpose[loanPurpose];
		this.keyIdentifier.isCashApplication = this.keyIdentifier.loanPurposeConfig.isCashApplication;
		this.sharedKeyDataService.announceIdentifierChanges(this.keyIdentifier);
		// this.setButtonText();
	}

	/**
	 * Product selection change
	 * @param product
	 */
	changeProductSelection(product: any) {
		this.selectedProductData = product;
		const productData = this.loanPurposes[product.id]
		this.skipSubProductStep = !productData.purposeCodeList;

		this.updateLoanPurposeConfig(product.value);
		if(!this.keyIdentifier.application) {
			this.keyIdentifier.application = new ApplicationModel();
		}
		this.keyIdentifier.application.productName = product.value;
		this.keyIdentifier.loanPurpose = product.value;
		if(productData.purposeCodeList) {
			/* ========== Set Sub Product List array based on category selection ========== */
			this.subProductListRadioObject = new RadioOptionModel();
			this.subProductListRadioObject.subProductTemplate = RadioTemplate.RadioCard;

			const subProductList = productData.purposeCodeList;
			subProductList.forEach((subProduct: {value: string, label: string}) => {
				this.subProductListRadioObject.items.push({
					heading: subProduct.label,
					name: subProduct.value,
					value: subProduct.value,
					id: subProduct.value,
				});
			});
		}
	}

	/**
	 * Sub Product selection change
	 * @param subProduct
	 */
	changeSubProductSelection(subProduct: any) {
		this.selectedSubProductData = subProduct;
		this.keyIdentifier.application.subProductName = subProduct;
	}

	/**
	 * Submit and go to Personal Information step of main stepper
	 */
	submitProductSelection() {
		this.cdr.detectChanges();
		if(this.subProductListRadioObject?.items?.length === 1 && !this.selectedSubProductData) {
			this.changeSubProductSelection(this.subProductListRadioObject.items[0]);
			this.submitProductSelection();
		} else {
			const isLastStep = this.productSelectionStepper.selectedIndex === this.productSelectionStepper.steps.length - 1;
			if (isLastStep) {
				this.sharedKeyDataService.announceIdentifierChanges(this.keyIdentifier);
				this.selectedProduct.emit(this.loanPurposes[this.selectedProductData.id]);
				this.productSelectionComplete.emit();
			} else {
				// if subProduct selection is remaining
				this.productSelectionStepper.next();
			}
		}
	}

	ngOnDestroy() {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
