import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {KeyIdentifierModel} from '@creditsnap/data-models';
import {SharedKeyDataService} from './sharedkey-data.service';
import {APP_ENV_CONFIG, ApplicationService, SocketService} from '../index';


@Injectable({
	providedIn: 'root'
})
export class ProvisionService {
	keyIdentifier: KeyIdentifierModel;
	apiTimeout: number = 5 * 1000;
	apiInterval: number = 5 * 1000;
	maxTimeout: number = 60 * 1000;
	delayTimeout: number = 60 * 1000;
	currentTimeout: number = 0;
	currentFeatureData: any;
	checkStatusCall: any;
	currentProvision: Subject<any> = new Subject();
	mapFeatureWithStatus: any = {
		payment: 'PAYMENT_SUCCESS',
		esign: 'DOCS_SIGNED',
		kyc: 'VERIFICATION_SUCCESS',
		scheduling: 'VERIFICATION_SUCCESS'
	};

	constructor(
		private http: HttpClient,
		private applicationService: ApplicationService,
		private socketService: SocketService,
		private sharedKeyDataService: SharedKeyDataService,
		@Inject(APP_ENV_CONFIG) private env: any,
		private router: Router
	) {
		this.currentProvision.subscribe(data => {
			console.log('result12312', data);
			this.currentFeatureData = data;
		});
		sharedKeyDataService.keyIdentifier$.subscribe((data) => {
			this.keyIdentifier = data;
		});
	}

	/**
	 * Get next provision for application
	 * @param _applicationId
	 */
	getNextProvision(_applicationId: number) {
		return new Promise((resolve, reject) => {
			this.http.get<any>(this.env.apiUrl + `/provision/application/${_applicationId}`).subscribe({
				next: result => {
					const { feature, statusVerifyMethod: verifyMethod, isConditional } = result;
					//this.loadingSubject.next(false);
					if (feature.toLowerCase().includes('thankyou')) {
						resolve(result);
						this.router.navigate([`/provision/thankyou`], { state: { messageName: feature } });
					} else {
						resolve(result);
						this.currentProvision.next({ name: feature.toLowerCase(), verifyMethod, isConditional });

						this.keyIdentifier.isConditionalProvision = result.isConditional;
						this.sharedKeyDataService.announceIdentifierChanges(this.keyIdentifier);

						this.router.navigate([`/provision/${feature.toLowerCase()}`], { state: { verifyMethod: (verifyMethod || '').toUpperCase() } });
					}
				}, error: error => {
					resolve(true);
					//this.loadingSubject.next(false);
					this.router.navigate([`/provision/thankyou`]);
				}
			});

		});
	}

	skipToNextProvision(_applicationId: number) {
		// console.log(this.currentFeatureData.name); return;
		this.http.post<any>(this.env.apiUrl + `/provision/skip/${this.currentFeatureData.name}/application/${_applicationId}`, {}).subscribe(res => {
			this.verifyAndRedirectProvisions('REALTIME', _applicationId);
		});
	}

	verifyAndRedirectProvisions(verificationMethod: 'POLLING' | 'REALTIME' | 'SOCKET' | 'NONE', applicationId: number, socketUrl: string = '') {
		switch (verificationMethod) {
			case 'POLLING':
				// KYC, INCOME VERIFICATION example (will slowly move to socket)
				this.verifyByPolling(applicationId);
				break;
			case 'REALTIME':
				// PRE-REGISTRATION, UPLOAD_DOCS, PAYMENT=ACH/internalTransfer example
				this.verifyByRealTime(applicationId);
				break;
			case 'SOCKET':
				// PAYMENT=CARD example
				this.verifyBySocket(applicationId, socketUrl);
				break;
			case 'NONE':
				console.log('UPDATE FROM FRONTEND');
		}
	}


	verifyByPolling(applicationId: number) {
		if (this.currentTimeout >= this.maxTimeout) {
			return;
		}
		this.currentTimeout = this.currentTimeout + this.apiTimeout;
		setTimeout(() => {
			this.checkStatusCall = setInterval(() => this.getApplicationStatus(applicationId), this.apiInterval);
		}, this.delayTimeout);
	}


	async verifyByRealTime(applicationId: number) {
		// this.router.navigate(['/provision'], { relativeTo: this.activatedRoute });
		await this.getNextProvision(applicationId);
		// this.router.navigate(['/redirect'], {
		// 	queryParams: {
		// 		appId: applicationId,
		// 		event: 'success',
		// 		feature: this.currentFeatureData.name
		// 	}
		// });
	}


	verifyBySocket(applicationId: number, sessionId: string) {
		// start checking application status after 60 seconds with interval of 5 seconds in case socket doesn't send the event.
		setTimeout(() => {
			this.checkStatusCall = setInterval(() => this.getApplicationStatus(applicationId), this.apiInterval);
		}, this.delayTimeout);
		this.socketService.connectAsync(sessionId).then(res => {
			if (res) {
				this.socketService.socketDataSource.subscribe(res => {
					if (res && res.action === 'CHECKPAYMENTSTATUS' || res.action === 'CHECKIDVERIFICATIONSTATUS') {
						this.router.navigate(['/redirect'], {
							queryParams: {
								appId: applicationId,
								event: 'success',
								feature: this.currentFeatureData.name
							}
						});
					}
				});
			}
		});
	}

	getApplicationStatus(applicationId: number) {

		this.applicationService.findApplication(applicationId)
			.subscribe(async res => {
				if (this.mapFeatureWithStatus[this.currentFeatureData.name] && this.mapFeatureWithStatus[this.currentFeatureData.name] === res.losStatus) {
					this.router.navigate(['/redirect'], {
						queryParams: {
							appId: applicationId,
							event: 'success',
							feature: this.currentFeatureData.name
						}
					});
				} else {

				}
			}, error1 => {
				this.router.navigate(['/myapp']);
			});
	}


	getUnderwritingDecision(_applicationId) {
		return this.http.get<any>(this.env.apiUrl + `/underwriting/application/${_applicationId}`);
	}

	validateRoutingNumber(rn: string) {
		const url = 'https://7MV7A9XWSE.algolia.net/1/indexes/routingNumber/query';
		const body = {
			query: `${rn}`,
			filters: "institutionlookup:routingNumber",
			attributesToRetrieve: [
				"code",
				"value"
			]
		}

		return this.http.post(url, body, {
			headers: {
				'X-Skip-Headers': this.env.auth.interceptorSkipHeader,
				'X-Algolia-Api-Key': this.env.ALGOLIA_API_KEY,
				'X-Algolia-Application-Id': this.env.ALGOLIA_APPLICATION_ID
			}
		});
	}

}
