import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective, NG_VALIDATORS, NgControl, NgForm} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusMonitor} from '@angular/cdk/a11y';
import {
    AsYouType,
    CountryCode,
    CountryCode as CC,
    E164Number,
    NationalNumber,
    parsePhoneNumberFromString,
    PhoneNumber
} from 'libphonenumber-js/max';
import {MatMenu} from '@angular/material/menu';
import {phoneNumberValidator} from './intl-tel-input.validator';
import {Country, CountryList} from '@creditsnap/data-models';

export type PhoneNumberFormat = 'default' | 'national' | 'international';

@Component({
    selector: 'mat-intl-tel-input',
    templateUrl: './intl-tel-input.component.html',
    styleUrls: ['./intl-tel-input.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: IntlTelInputComponent
        },
        {
            provide: NG_VALIDATORS,
            useValue: phoneNumberValidator,
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntlTelInputComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {

    @Input() preferredCountries: Array<CountryCode> = [];
    @Input() enablePlaceholder = true;
    @Input() inputPlaceholder: string = 'Enter your phone number';
    @Input() cssClass: string;
    @Input() name: string;
    @Input() onlyCountries: Array<CountryCode> = [];
    @Input() enableSearch = false;
    @Input() searchPlaceholder: string;
    @Input() describedBy = '';

    @Input()
    get format(): PhoneNumberFormat {
        return this._format || 'national';
    }

    set format(value: PhoneNumberFormat) {
        this._format = value;
        this.phoneNumber = this.formattedPhoneNumber;
        this.stateChanges.next(null);
    }

    @ViewChild(MatMenu) matMenu: MatMenu;

    private _placeholder: string | undefined;
    private _required = false;
    private _disabled = false;
    stateChanges = new Subject<void>();
    focused = false;

    @HostBinding()
    id: string;
    phoneNumber: NationalNumber | string;
    allCountries: Array<Country> = [];
    preferredCountriesInDropDown: Array<Country> = [];
    selectedCountry: Country;
    numberInstance: PhoneNumber;
    value: E164Number | string;
    searchCriteria: string;

    autofilled: boolean;
    controlType: string;
    errorState: boolean;
    userAriaDescribedBy: string;

    @Output() countryChanged = new EventEmitter<Country>();

    countryCodeData = CountryList;

    private previousFormattedNumber: string;
    private _format: PhoneNumberFormat = 'national';

    onTouched = () => {
    };

    propagateChange = (_: any) => {
    };

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() private _parentForm: NgForm,
        @Optional() private _parentFormGroup: FormGroupDirective
    ) {
        fm.monitor(elRef, true).subscribe((origin) => {
            if (this.focused && !origin) {
                this.onTouched();
            }
            this.focused = !!origin;
            this.stateChanges.next(undefined);
        });
        this.fetchCountryData();
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        if (!this.searchPlaceholder) {
            this.searchPlaceholder = 'Search Country';
        }

        if (this.onlyCountries.length) {
            this.allCountries = this.allCountries.filter((c) =>
                this.onlyCountries.includes(c.alpha2)
            );
        }

        if (this.preferredCountries.length > 0 && this.allCountries.length > 5) {
            this.preferredCountries.forEach((alpha2) => {
                const preferredCountry = this.allCountries
                    .find((c) => c.alpha2.toLowerCase() === alpha2.toLowerCase());

                if (preferredCountry) {
                    this.preferredCountriesInDropDown.push(preferredCountry);
                }
            });
        }

        if (this.numberInstance && this.numberInstance.country) {
            // If an existing number is present, we use it to  selectedCountry
            this.selectedCountry = this.getCountry(this.numberInstance.country);
        } else {
            if (this.preferredCountriesInDropDown.length) {
                this.selectedCountry = this.preferredCountriesInDropDown[0];
            } else {
                // default to US
                this.selectedCountry = this.getCountry('US');
            }
        }

        this.countryChanged.emit(this.selectedCountry);
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next(null);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.ngControl.value) {
                this.numberInstance = parsePhoneNumberFromString(this.ngControl.value);

                if (this.numberInstance) {
                    const countryCode = this.numberInstance.country;
                    this.phoneNumber = this.formattedPhoneNumber;
                    if (!countryCode) {
                        return;
                    }
                    this.selectedCountry = this.getCountry(countryCode);
                    if (this.selectedCountry.dialCode && !this.preferredCountries.includes(this.selectedCountry.alpha2)) {
                        this.preferredCountries.push(this.selectedCountry.alpha2);
                        this.preferredCountriesInDropDown.push(this.selectedCountry);
                    }
                    this.countryChanged.emit(this.selectedCountry);
                } else {
                    this.phoneNumber = this.ngControl.control.value;
                }

                this._changeDetectorRef.markForCheck();
                this.stateChanges.next(undefined);
            }
        }, 0);
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    private updateErrorState() {
        const parent = this._parentFormGroup || this._parentForm;
        const oldState = this.errorState;
        const newState = this.ngControl?.invalid && (this.ngControl.touched || parent.submitted);


        if (this.phoneNumber) {
            const phone = this.phoneNumber.toString().replace(/([\s,.€()_-])+/g, '');
            const parsedNumber = parsePhoneNumberFromString(phone, this.selectedCountry.alpha2.toUpperCase() as CountryCode);

            if (!parsedNumber || !parsedNumber.isValid() || phone.includes('0000') || parsedNumber.country !== this.selectedCountry.alpha2) {
                this.ngControl.control.setErrors({invalid: true});
            } else {
                this.ngControl.control.setErrors(null);
            }
        } else {
            this.ngControl.control.setErrors({required: true});
        }

        if (oldState !== newState) {
            this.errorState = newState;
            this.stateChanges.next();
        }
    }

    onPhoneNumberAutoFill(event: InputEvent | Event): void {
        if (!(event as InputEvent).inputType) {
            // Timeout is added for browser autofill
            setTimeout(() => {
                // remove 0 as browser autofill can add zero prefix
                if (this.phoneNumber.charAt(0) === '0') {
                    this.phoneNumber = this.phoneNumber.substring(1);
                }
                this.onPhoneNumberChange();
            });
        }
    }

    public onPhoneNumberChange(): void {

        try {
            this.numberInstance = parsePhoneNumberFromString(`+${this.selectedCountry.dialCode}${this.phoneNumber}`);
            this.formatAsYouTypeIfEnabled();
            this.value = this.numberInstance?.number;
            if (this.numberInstance && this.numberInstance.isValid()) {
                if (this.phoneNumber !== this.formattedPhoneNumber) {
                    this.phoneNumber = this.formattedPhoneNumber;
                }
            } else {
                this.ngControl.control.setErrors({invalid: true});
            }
        } catch (e) {
            // if no possible numbers are there,
            // then the full number is passed so that validator could be triggered and proper error could be shown
            this.value = this.phoneNumber?.toString();
        }

        this.propagateChange(this.value);


        //this._changeDetectorRef.markForCheck();
    }

    public onCountrySelect(country: Country, el: HTMLInputElement): void {
        if (this.phoneNumber) {
            this.phoneNumber = this.numberInstance?.nationalNumber;
        }
        this.selectedCountry = country;
        this.countryChanged.emit(this.selectedCountry);

        this.onPhoneNumberChange();
        el.focus();
    }

    public getCountry(code: string): Country {
        return <Country>(
            this.allCountries.find((c) => c.alpha2.toLowerCase() === code.toLowerCase()) || {
                name: 'UN',
                alpha2: 'UN',
                dialCode: '',
                flagClass: 'UN',
                placeHolder: ''
            }
        );
    }

    protected fetchCountryData(): void {
        this.countryCodeData.forEach((c) => {
            const country: Country = {
                alpha3: '',
                id: 0,
                zipRegex: '',
                name: c.name,
                alpha2: c.alpha2 as CountryCode,
                dialCode: c.dialCode,
                flagClass: c.alpha2.toUpperCase(),
                placeHolder: ''
            };

            this.allCountries.push(country);
        });
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next(undefined);
    }

    writeValue(value: string): void {
        if (value) {
            // to parse number and populate it, need to append +
            if (!value.startsWith('+')) {
                value = `+${value}`;
            }

            this.numberInstance = parsePhoneNumberFromString(value);
            if (this.numberInstance) {
                this.phoneNumber = this.formattedPhoneNumber;
            } else {
                this.phoneNumber = value;
            }
            this.value = value;
            this.propagateChange(this.value);
        }

        // Value is set from outside using setValue()
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next(undefined);
    }

    get empty(): boolean {
        return !this.phoneNumber;
    }

    @HostBinding('class.ngx-floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input()
    get placeholder(): string {
        return this._placeholder || '';
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next(undefined);
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next(undefined);
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next(undefined);
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elRef.nativeElement.querySelector('input')!.focus();
        }
    }

    reset(): void {
        this.phoneNumber = '';
        this.propagateChange(null);

        this._changeDetectorRef.markForCheck();
        this.stateChanges.next(undefined);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef);
    }

    private get formattedPhoneNumber(): string {
        if (!this.numberInstance) {
            return this.phoneNumber?.toString() || '';
        }

        switch (this.format) {
            case 'national':
                const formattedNumber = this.numberInstance.format('NATIONAL', {
                    nationalPrefix: false,
                    fromCountry: 'US'
                });
                if (formattedNumber.charAt(0) === '0') {
                    return formattedNumber.substring(1);
                } else {
                    return formattedNumber;
                }
            case 'international':
                return this.numberInstance.formatInternational();
            default:
                return this.numberInstance.nationalNumber.toString();
        }
    }

    private formatAsYouTypeIfEnabled(): void {
        if (this.format === 'default') {
            return;
        }
        const asYouType: AsYouType = new AsYouType(
            this.selectedCountry?.alpha2?.toUpperCase() as CC
        );
        // To avoid caret positioning we apply formatting only if the caret is at the end:
        if (
            this.phoneNumber?.toString().startsWith(this.previousFormattedNumber || '')
        ) {
            this.phoneNumber = asYouType.input(this.phoneNumber.toString());
        }
        this.previousFormattedNumber = this.phoneNumber?.toString();
    }
}
